@import '../../node_modules/rc-time-picker/assets/index.css'
@import '../../node_modules/onsenui/css/onsenui.min.css'
@import '../../node_modules/onsenui/css/onsen-css-components.min.css'

$red: #FB3640
$yellow: #FAA916
$green: #4D8B31
$blue: #0094C6
$grey: #6A7062
$dark: #0E3B43
$timeEntry: $yellow
$shift: $blue

$toolbarHeight: 44px

$top_h: 44px
$bottom_h: 44px
$title_h: 37px

.rrs__options
  top: 10% !important
  bottom: 15% !important

.HourMinutePicker
  background: #fff

.shiftSectionHeader
  background: #A8A391

.addEntryButton
  display: inline-block
  font-size: .6rem
  line-height: 1em
  border: 1px solid darken(#A8A391, 20%)
  color: darken(#A8A391, 60%)
  text-transform: uppercase
  padding: .5em
  border-radius: 3px
  background: lighten(#A8A391, 10%)

.otStatus
  .status
    color: $grey
    font-size: .8rem
  .ons-icon
    color: $grey
    margin-right: 4px

.text-center
  text-align: center

.action-sheet-button--disabled
  color: #ccc
  &:active
    background: rgba(255,255,255,.9)

.MobilePicker
  background: white
  position: absolute
  top: $title_h
  bottom: 0
  // height: calc(100vh - $top_h - $bottom_h - $title_h)
  width: 100vw
  display: flex
  // align-items: flex-start
  justify-content: stretch
  flex-direction: column
  .MobileTitle
    flex: 0
    height: $title_h
    line-height: $title_h
    text-align: center
    background: blue
    color: white
  .MobilePickerContainer
    min-height: 0
    flex: 1
    // scroll-snap-points-y: repeat(80px)
    // scroll-snap-type: mandatory
    // scroll-snap-type: y mandatory
    text-align: center
    height: 200px
    overflow-y: scroll
  .MobilePickerScroll
    // overflow-y: scroll

    // min-height: min-content
    section
      height: 100%
    .pickerItem
      height: 40px
      line-height: 40px
      border-bottom: 1px solid #ccc
      &.selected
        background: $blue
        color: white
  .MobilePickerFooter
    flex: 0
    height: 260px
    background: #ccc
    display: flex
    flex-direction: row
    justify-content: stretch
    .cancel
      background: $red
      color: #fff
    .save
      background: $green
      color: #fff
    div
      flex: 1
      text-align: center
      padding: 10px 20px

.TableFixedTh
  position: relative //fixed
  width: 100vw
  thead
    height: $toolbarHeight
    width: 100vw
    background: white
    tr
      height: $toolbarHeight
    th
      text-align: center
  tr
    border-top: 1px solid #ddd
    width: 100vw
    height: auto
    display: grid
    grid-template-columns: 4fr 3fr 3fr 2fr 2fr 2fr
    grid-template-rows: auto
    gap: 16px 10px
    justify-content: stretch
    justify-items: stretch
    align-content: stretch
    align-items: center
  tbody
    td
      border: none
      height: auto
      width: auto
      padding: 0
    label
      margin: 0 0 0 10px
  .StickyTableHeader
    position: fixed
    top: $toolbarHeight
    background: white

.DateColumn
  font-size: .9rem
  width: 5ch
  text-align: right
  // .WeekDay
  // .Month
  // .Date

.datepicker.ios .datepicker-header
  text-transform: capitalize
  line-height: 41px

.list-item
  cursor: 'pointer'

// .bottom-bar,
// .toolbar,
// .toolbar__item
//   height: $toolbarHeight
// .toolbar+.page__background,
// .toolbar+.page__background+.page__content
//   top: $toolbarHeight
// .page-with-bottom-toolbar>.page__content
//   bottom: $toolbarHeight
.dialog-container
  height: 40vh
  width: 90vw
.DialogModal
  height: 300px

#SearchSelect__drop.SelectOpen
  max-height: 100vh !important
  width: 100vw !important
  height: auto !important
  position: absolute !important
  top: 190px !important
  bottom: 90px !important
  left: 0 !important
  right: 0 !important
  #SearchSelect__select-drop
    height: auto
    top: 190px !important
    bottom: 90px !important
    left: 0 !important
    right: 0 !important

  .TimeSelector
    ul
      margin: 20rem 0 20rem 0
    li
      text-align: center
      font-size: 2rem
      padding: 2rem

.shiftRow
  display: flex
  justify-content: space-between
  .shiftDate
    font-size: .9rem
  .shiftShowMore
    span
      font-size: .7rem
  .shiftTimeBox
    text-align: center
    font-size: .9rem
    border-radius: 4px
    background: $blue
    color: white
    padding: .25rem .5rem
    // &.shiftStartTime
    // &.shiftEndTime
    &.shiftDuration
      width: 5ch

.List
  margin: 0
  width: 100vw
  li
    padding: 0
  .ListItem
    display: flex
    align-items: center
    background: white
    // &.withRightAction
    &.rejected
      background: $red
      color: white
    &.active
      background: magenta
      color: white
    .ListItemContent
      flex: 1
      padding: .5rem 1rem
    .ListItemAction
      flex: 0
      padding: 1rem

.card
  .rc-time-picker
    font-family: -apple-system,'Helvetica Neue',Helvetica,Arial,'Lucida Grande',sans-serif
    margin-right: 10px
    width: 80px
    .rc-time-picker-input
      font-size: 1rem
      height: 36px
      padding: 6px 9px

    .rc-time-picker-panel-input-wrap
      font-family: -apple-system,'Helvetica Neue',Helvetica,Arial,'Lucida Grande',sans-serif
      font-size: 1rem
      padding: 0

  .rc-time-picker-panel-input
    font-size: 1rem !important
    height: 36px
    padding: 6px 9px

  .rc-time-picker-panel,
  .rc-time-picker-panel-select
    color: lighten(black, 40%)
    font-size: 1rem
    font-family: -apple-system,'Helvetica Neue',Helvetica,Arial,'Lucida Grande',sans-serif

  .rc-time-picker-panel-select-option-selected
    font-weight: 700

// .rc-time-picker-panel-inner
// .rc-time-picker-panel-input-wrap
// .rc-time-picker-panel-input
// .rc-time-picker-panel-combobox
// &

.list-item
  &[status="sick"]
    background: lighten(orange, 40%)
  &[status="submitted"]
    background: lighten(blue, 40%)
  &[status="rejected"]
    background: lighten(red, 60%)
  &[status="approved"]
    background: lighten(green, 60%)
  &[status="pto"]
    background: lighten(purple, 60%)

.loginInput
  width: 100%

.loginCard
  min-height: 400px
  display: flex
  flex-direction: column
  justify-content: space-between

header
  text-align: center
  padding: 4px 20px
  // border-bottom: 1px solid #ccc

.speedDialNav
  .navItemFab
    overflow: visible
    opacity: 1
    label
      position: absolute
      height: auto
      right: 46px
      top: 9px
      border-radius: 8px
      line-height: 1em
      background: rgba(black, 80%)
      padding: 4px 8px
      color: white
      font-size: .9rem
      z-index: -1

  .fabNav
    &:hover
      opacity: 1
    &.closed
      opacity: .3
    &.open
      opacity: 1

.shiftDetails
  header
    display: block
    padding: .5rem
    text-align: center
  .shiftDate
    text-align: center
  .shiftTime
    background: #fff
    // padding: 1rem
    display: flex
    justify-content: space-between
    align-items: center
    .shiftTimeLabel
      display: block
      text-align: center
      margin-bottom: 8px
      text-transform: uppercase
      font-weight: 200
    .shiftTimeValue
      display: block
      text-align: center
      padding: 8px
      border-radius: 8px
      background: #D8D8D8
      color: #fff
      font-weight: 600

.sectionHeader
  display: flex
  justify-content: space-between
  align-items: center
  font-size: .9rem
  .icon
    font-size: 1.3rem
  div
    padding: 1em

.jobPill
  display: inline-block
  background: #ccc
  color: #fff
  border-radius: 4px
  font-size: .6em
  font-weight: 600
  padding: 3px 5px
  margin: 0 .5rem
  line-height: 1em
  border: 1px solid darken(#ccc, 10%)

ons-icon p
  font-size: 1rem
  text-transform: uppercase
  margin-top: 4px

#ShiftsPage
  .page__content
    padding-top: 90px
.ShiftsStickyHeader
  z-index: 9
  background: white
  position: fixed
  top: 44px
  height: 90px
  .PayPeriodNav
    display: flex
    flex-direction: row
    div
      padding: 10px
    .left, .right
      width: 80px
      flex: 0
    .center
      flex: 1
      text-align: center
  // .ShiftsStickyHeaderRow


#status 
  background: #FFF
  //width: 100%
  //font: bold 1em sans-serif
  //color: #FFF
  //padding: 0.5em
 
.online
  color: dodgerblue
.offline
  color: red


.xonline
  height: 5px
  width: 5px
  border-radius: 50%
  display: inline-block
  background: green

.xoffline 
  height: 5px
  width: 5px
  border-radius: 50%
  display: inline-block
  background: red

.pto
    color: green    
    b
      text-transform: uppercase
.sick
    color: dodgerblue
    b
      text-transform: uppercase
.ot
    color: red
    b
      text-transform: uppercase
.reg
    color: black
    b
      text-transform: uppercase
      
.approveDivLoading
    margin: 0px
    padding: 0px
    right: 0px
    top: 0px
    position: absolute
    width: 100%
    height: 100%
    z-index: 30001

.approveProgressLoading
    position: absolute
    color: White
    top: 50%
    left: 45%

